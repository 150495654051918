import { IUser } from '@bvi/api-interfaces/entity/user';
import { IGetCaseStatisticsRequest } from '@bvi/api-interfaces/request/case';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';

import { IBaseProperties } from './base';
import { ICaseProperties } from './case-property';
import { IResolution } from './resolution';

/**
 * In order to avoid type self-references, we should define *Base interfaces
 * containing only data properties, and then extend them to define all relation
 * properties.
 *
 * We should use only *Base interfaces in relation properties.
 */

export interface ICaseBaseProperty extends IBaseProperties {
  name: string;
}

export interface IStateBase extends ICaseBaseProperty {
  name: string;
}

export interface IState extends IStateBase {
  region?: IRegion;
  country?: ICountry;
}

export interface IRegionBase extends ICaseBaseProperty {
  name: string;
  code: string;
}

export interface IRegion extends IRegionBase {
  states: Array<IState> | null;
  country?: ICountry;
}

export interface IJobTitle extends ICaseBaseProperty {}

export interface IOrganizationBase extends ICaseBaseProperty {}

export interface IOrganization extends IOrganizationBase {
  type: IOrganizationType | null;
}

export interface IOrganizationType extends ICaseBaseProperty {}

export interface IDamage extends ICaseBaseProperty {}

export interface IGroup extends ICaseBaseProperty {}

export interface IOrder extends ICaseBaseProperty {}

export interface IDiocese extends ICaseBaseProperty {}

export interface IException extends ICaseBaseProperty {}

export interface ILiabilityDefense extends ICaseBaseProperty {}

export interface ICountryBase extends ICaseBaseProperty {}

export interface ICountry extends ICountryBase {
  regions: Array<IRegion>;
  states: Array<IState>;
}

export interface ICaseBase extends IBaseProperties {
  name: string | null;
  description: string | null;
  claimantName: string | null;
  isResolved: boolean;
  properties: ICaseProperties;
  status: CaseStatus;
}

export interface ICase extends ICaseBase {
  order: IOrder | null;
  diocese: IDiocese | null;
  organization: IOrganization | null;
  organizationType: IOrganizationType | null;
  jobTitle: IJobTitle | null;
  country: ICountry | null;
  region: IRegion | null;
  state: IState | null;
  resolution: IResolution | null;
  needsReview: boolean;
  userCase: IUserCaseWithCreator | null;
  exception: IException | null;
  liabilityDefenses: Array<ILiabilityDefense> | null;
  damage: IDamage | null;
  group: IGroup | null;
}

export interface IUserCaseNote extends IBaseProperties {
  title: string;
  text: string;
}

export interface IUserCaseBase extends IBaseProperties {
  notes: Array<IUserCaseNote>;
  perCompanyId: number | null;
  declineReason: string | null;
  creatorId: number;
  caseId: number;
}

export interface IUserCaseWithCreator extends IUserCaseBase {
  creator: IUser;
}

export interface IUserCaseWithCase extends IUserCaseBase {
  case: ICase;
}

export interface IUserCase extends IUserCaseWithCreator, IUserCaseWithCase {}

export enum CaseStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  CLOSED = 'closed',
}

export enum CaseEvents {
  Submit = 'submit',
  Approve = 'approve',
  Deny = 'deny',
  Close = 'close',
  Reopen = 'reopen',
}

export interface ISavedSearchEntryBase extends IBaseProperties {
  statistics: ICaseStatisticsData;
}

export interface ISavedSearchEntry extends ISavedSearchEntryBase {
  user: IUser;
}

export interface ISavedSearchBase extends IBaseProperties {
  name: string;
  parameters: IGetCaseStatisticsRequest;
  isUpdatesAvailable: boolean;
}

export interface ISavedSearch extends ISavedSearchBase {
  entries: Array<ISavedSearchEntryBase>;
  user: IUser;
}
